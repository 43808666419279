<script setup>
 // Composables
 import { getEnv } from '@/composables/helpers/getEnv';

 const { menu, absoluteLinks } = defineProps({
  menu: {
   type: Object,
   required: true,
  },
  absoluteLinks: {
   type: Boolean,
   default: false,
  },
 });

 //  Hack for TNEW
 const clientFrontEnd = getEnv('clientFrontEnd');
 const makeLink = (url) => {
  if (absoluteLinks) return clientFrontEnd + url;
  return url;
 };
</script>
<template>
 <section class="multi-column-links">
  <div class="container">
   <ul class="columns">
    <li v-for="(column, index) in menu" :key="index" class="column">
     <div>
      <MessLink :href="makeLink(column.url)" :forceExternalLink="absoluteLinks">
       <h3 class="column-headline">
        {{ column.label }}
       </h3>
      </MessLink>
      <ul>
       <li v-for="(link, linkIndex) in column.subitems" :key="linkIndex" class="column-link">
        <MessLink :href="makeLink(link.url)" :forceExternalLink="absoluteLinks">
         {{ link.label }}
        </MessLink>
       </li>
      </ul>
     </div>
    </li>
   </ul>
  </div>
 </section>
</template>

<style lang="scss">
 .global-footer {
  .multi-column-links {
   .columns {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: $tablet) {
     margin: 0 -5px;
    }

    @media (min-width: $desktop) {
     flex-wrap: nowrap;
    }

    .column {
     flex: 0 0 calc(50%);
     margin-bottom: 30px;

     @media (min-width: $mobile) {
      flex: 0 0 calc(33.33%);
     }

     @media (min-width: $tablet) {
      flex: 0 0 calc(14.28% - 10px);
      margin: 0 5px;
     }

     @media (min-width: $desktop) {
      flex: 1 1 auto;
      margin: 0 5px;
     }
    }
   }

   .column-headline {
      font-size: calc(16 / var(--base-fs) * 1rem);
      font-weight: 900;
      line-height: 1.25;
      letter-spacing: 1.61px;
      text-transform: uppercase;

      @media (min-width: $tablet) {
         font-size: calc(14 / var(--base-fs) * 1rem);
      }
   }

   .column-link,
   .column-link * {
      font-size: calc(16 / var(--base-fs) * 1rem);
      font-weight: 350;
      line-height: 1.56;
      letter-spacing: 0.21px;

      @media (min-width: $tablet) {
         font-size: calc(14 / var(--base-fs) * 1rem);
      }
   }
      }
   }
</style>
